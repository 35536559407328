<template>
  <section class="desktop_paper_3">
    <div class="content_box">
      <div class="title_3">
        <h2 class="title_color_3">壁纸桌面</h2>
        <h3>千万风格随心自定</h3>
      </div>

      <div class="imgs1_3" bg-url="@/auto/assets/image/3/wallpaper_1.jpg" v-bg></div>
      <div class="imgs2_3" bg-url="@/auto/assets/image/3/wallpaper_2.jpg" v-bg></div>
      <div class="first_desktop_3" bg-url="@/auto/assets/image/3/wallpaper.png" v-bg></div>

      <p class="desc_3">多样风格的系统壁纸，总有一款适合你。在重要节日应景地推出节日壁纸，<br />也可以进行自定义，将自己难忘的时刻与回忆定格在屏幕上</p>
    </div>
  </section>
</template>

<script>


export default {
  name: 'DesktopPaper',

  data() {
    return {
      titleChange: false
    }
  },

  methods: {
    pagePin() {
      this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.desktop_paper_3',
          end: '+=3000',
          pin: true,
          // markers: true
        }
      })
    },

    fontChange() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.title_color_3',
          start: 'top center',
          end: '500px center',
          scrub: 0.1,
          // markers: true,
        }
      })

      tl.to('.title_color_3', { backgroundPositionX: '0%' })
    },

    startFirstDesktop() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: '.first_desktop_3',
          start: '0px center',
          end: '3000px center',
          scrub: 0.1,
          // markers: true
        }
      })

      tl.addLabel('fontVisible')
      tl.to('.title_3', { y: -300, opacity: 0 }, 'fontVisible')
      tl.to('.first_desktop_3', { borderWidth: 0, borderRadius: 0, top: 60, left: 476, width: 560, height: 350 }, 'fontVisible')

      tl.addLabel('otherVisible')
      tl.to('.imgs1_3', { opacity: 1, duration: 0.3 }, 'otherVisible')
      tl.to('.imgs2_3', { opacity: 1, duration: 0.3 }, 'otherVisible')
      tl.to('.desc_3', { opacity: 1, duration: 0.3 }, 'otherVisible')
      
      tl.addLabel('imgsMove')
      tl.to('.first_desktop_3', { left: -392 }, 'otherVisible')
      tl.to('.imgs1_3', { right: 0 }, 'otherVisible')
      tl.to('.imgs2_3', { left: 0 }, 'otherVisible')
    },

    onLaunch() {
      this.pagePin() 
      this.fontChange()
      this.startFirstDesktop() 
    },
  }
}
</script>

<style lang="less" scoped>
.desktop_paper_3 {
  height: 100vh;
  min-height: 1080px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #17181C;

  .content_box {
    width: 1920px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .title_3 {
    margin: 160px 0 50px 0;
    
    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      width: 230px;
      margin: auto;
      background: linear-gradient(135deg, #068CF2 0%, #16E4B1 40%, #fff 60%, #fff 100%) no-repeat;
      background-position-x: 100%;
      background-size: 250% 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    
    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 79px;
      text-align: center;
      color: #fff;
      margin: 0;
    }
  }

  .imgs1_3 {
    position: absolute;
    top: 60px;
    right: -864px;
    height: 350px;
    width: 2890px;
    background-size: 100% 100%;
    opacity: 0;
  }
  .imgs2_3 {
    position: absolute;
    top: 434px;
    left: -864px;
    height: 350px;
    width: 2890px;
    background-size: 100% 100%;
    opacity: 0;
  }
  .first_desktop_3 {
    position: absolute;
    top: 378px;
    left: 550px;
    width: 800px;
    height: 500px;
    box-sizing: content-box;
    background-size: 100% 100%;
    border: 10px solid #000000;
    border-radius: 12px;
  }

  .desc_3 {
    position: absolute;
    top: 864px;
    width: 792px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    opacity: 0;
  }
}
</style>
