<template>
  <transition>
    <div class="top_nav" v-show="visible">
      <div class="nav_box">
        <a
          class="logo"
          href="/"
          bg-url="@/auto/assets/image/0/logo.png"
          v-bg
        ></a>
        <ul class="nav_list">
          <li><a href="https://www.flyme.com" target="_blank">Flyme</a></li>
          <li><a href="/plan.html" target="_blank">Flyme Auto Core</a></li>
          <li><a href="https://open.flyme.cn/" target="_blank">开放平台</a></li>
          <li>
            <a href="https://open.flymeauto.com/" target="_blank">无界智行</a>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { scrollHandle } from '../bus_key'
import { debounce, getScrollTop, createSection } from '@/utils'
export default {
  name: 'topNav',
  data() {
    return {
      visible: true,
    }
  },

  mounted() {
    let scrollTop = 0

    this.$event.on(
      scrollHandle,
      debounce((e) => {
        const curTop = getScrollTop()

        if (curTop + 2000 < scrollTop || curTop === 0) {
          this.visible = true
        } else {
          this.visible = false
        }

        scrollTop = curTop
      }, 500),
    )
  },
}
</script>

<style lang="less" scoped>
.top_nav {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 72px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(60px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.nav_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 1200px;
  padding: 0 40px;
}

.logo {
  width: 173px;
  height: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.nav_list {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & + li {
    padding-left: 40px;
  }
}

a {
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    color: #fff;
  }
}
</style>
